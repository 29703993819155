const config = {
    brand: {
        name: ['De','Schoenen ','Webstore'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Voor de stoere peuter',
            subtitle: 'De collectie van Wednesday Addams is nu ook in kleur verkrijgbaar.'
        }
    ]
}

export { config }